import React, { useState } from 'react';
import OnVisible from 'react-on-visible';
import { BlogCard, Layout, Link } from 'components';
import loadingIcon from '../images/loading-tail-spin.svg';
import './blog.scss';

export const Blog = ({ location, pageContext }) => {
  const { title, posts, categories } = pageContext;
  const seo = {
    title: `${title} | YORDAR`,
  };
  // Infinite load logic
  const perPage = 9;
  const [numOfPosts, setNumOfPost] = useState(perPage);
  const [reachedEnd, setReachedEnd] = useState(false);
  // Called when visibility sensor at bottom of blog list is hit
  const fetchMore = isVisible => {
    if (isVisible) {
      if (numOfPosts < posts.length) {
        setTimeout(() => setNumOfPost(numOfPosts + perPage), 200);
      } else {
        setReachedEnd(true);
      }
    }
  };

  const [showCategories, setShowCategories] = useState(false);

  const toggleCategories = event => {
    event.preventDefault();
    setShowCategories(!showCategories);
  };

  return (
    <Layout location={location} seo={seo}>
      <section className="blog-page">
        <div className="wrapper">
          <h1 className="blog-page-title">{title}</h1>
        </div>
        {categories && categories.length > 0 && (
          <div className="wrapper blog-categories-wrapper">
            <Link
              onClick={toggleCategories}
              to="#show-categories"
              className={`category-toggle ${showCategories ? 'active' : 'inactive'}`}
            >
              Categories
            </Link>
            <div className={`blog-categories ${showCategories ? 'active' : 'inactive'}`}>
              <Link to="/blog">All</Link>
              {categories.map(cat => (
                <Link to={`/category/${cat.uid}`}>{cat.label}</Link>
              ))}
            </div>
          </div>
        )}
        <div className="wrapper">
          <section className="blog-grid">
            {posts.slice(0, numOfPosts).map(post => (
              <BlogCard key={post.id} uid={post.uid} {...post.data} />
            ))}
          </section>
          {!reachedEnd && (
            <OnVisible className="loading" onChange={fetchMore} bounce>
              <img src={loadingIcon} alt="Fetching more posts" />
            </OnVisible>
          )}
        </div>
      </section>
    </Layout>
  );
};

export default Blog;
